* {
  box-sizing: border-box;
}

html,
body,
#root {
  color: black;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Work Sans", sans-serif;
}

body {
  background: #dbe0ee;
}

#video {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}
